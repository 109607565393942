@media screen and (max-width: 1024px ) {
    .tokenomicsSection .container {
        flex-direction: column;
        align-items: center !important;
    }

    .tokenomicsSection .container .descriptionContainer {
        width: 100%;
        max-width: unset !important;
    }
}

    @media screen and (max-width: 768px ) {

    .tokenomicsSection .container .tokenomicsItemsContainer {
        width: 100% !important;
        justify-content: center !important;
    }

    .tokenomicsSection .container .tokenomicsItemsContainer .tokenomicsColumn.first {
        align-items: center;
    }
}

@media screen and (max-width: 425px ) {
    .tokenomicsSection{
        padding: 25px 0 0 !important;
    }
    .tokenomicsSection .container .tokenomicsItemsContainer {
        flex-direction: column !important;
        align-items: center !important;
    }

    .tokenomicsSection .container .tokenomicsItemsContainer .tokenomicsColumn {
        margin: 0 !important;
        width: 100% !important;
        align-items: center;
    }
    .tokenomicsSection .container .descriptionContainer {
        max-width: unset !important;
        width: 100% !important;
    }
}