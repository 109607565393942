.container {
    width: 100%;
    max-width: 1160px;
}
@media screen and ( max-width: 1024px ){
    .container {
        margin: 0 100px;
        /*width: unset !important;*/
    }
}
@media screen and ( max-width: 768px ){
    .container {
        margin: 0 50px;
        width: unset !important;
    }
}

