.navBar {
    width: 100%;
    max-width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgb(171, 105, 204);
    padding: 30px 25px;
    color: #ffffff;
    position: relative;
}

.navBar .navMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navBar .button {
    margin: 15px 0;
}

.navBar .navMenu a {
    margin: 10px 0;
    text-decoration: none;
    color: #ffffff;
}

.navBar .closeIcon {
    position: absolute;
    top: 1.5%;
    right: 2.5%;
    font-size: 30px;
}

.navBar .socialLinks {
    width: 100%;
    display: flex;
    margin: 10px 0;
}

.navBar .socialLinks .soc {
    margin: 0 20px 0 0;
}