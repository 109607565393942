@import "./responsive.css";

.tokenomicsSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1e0f3d;
    padding: 115px 0;
    overflow: hidden;
    position: relative;
}

.tokenomicsSection .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tokenomicsSection .container .descriptionContainer {
    width: calc(100% / 2);
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tokenomicsSection .container .imageContainer {
    width: 100%;
    max-width: 80px;
}

.tokenomicsSection .container .imageContainer #coin {
    width: 550px;
}

.tokenomicsSection .container h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #ffffff;
    margin: 15px 0 30px;
}

.tokenomicsSection .container .descriptionContainer p {
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #D9D4E0;
    opacity: 0.7;
}

.tokenomicsSection .container .descriptionContainer .button {
    color: #FFFFFF;
    background: #AB69CC;
    padding: 15px 45px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border: none;
    cursor: pointer;
    margin: 30px 0;
}

.tokenomicsSection .container .tokenomicsItemsContainer {
    width: calc(100% / 2);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tokenomicsSection .container .tokenomicsItemsContainer .tokenomicsColumn {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.tokenomicsSection .container .tokenomicsItemsContainer .tokenomicsColumn.first {
    margin-top: 40px;
}

.tokenomicsSection .container .tokenomicsItem {
    width: 100%;
    max-width: 260px;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 16px;
    color: #D9D4E0;
    /*background: #1B0E36;*/
    margin-bottom: 40px;
    transition: all 0.3s;
    cursor: pointer;
    border: 3px solid transparent;
    background: linear-gradient(#1B0E36, #1B0E36) padding-box,
    linear-gradient(123.35deg, #EBF3D0 0%, rgba(235, 243, 208, 0) 18.4%), radial-gradient(29.9% 70.94% at 44.25% 86.96%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(33.83% 53.57% at 35.87% 100%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(42.66% 49.72% at 45.56% 44.65%, #E1ADEB 0%, rgba(194, 166, 241, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(131.97deg, #CDF9F4 5.58%, rgba(205, 249, 232, 0) 47.58%), linear-gradient(216.44deg, rgba(192, 169, 240, 0) -16.52%, #C0A9F0 -1.04%, rgba(192, 169, 240, 0) 16.99%), linear-gradient(128.53deg, rgba(192, 169, 240, 0) 28.63%, #C0A9F0 38.5%, rgba(192, 169, 240, 0) 50.26%), radial-gradient(40.75% 97.37% at 90.75% 40.15%, #FFFDB1 0%, #FEE4BF 34.46%, #F0BDD0 69.5%, rgba(255, 129, 38, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #C2A6F1;

}

.tokenomicsSection .container .tokenomicsItem:hover {
    color: #211E31 !important;
    background: linear-gradient(123.35deg, #EBF3D0 0%, rgba(235, 243, 208, 0) 18.4%), radial-gradient(29.9% 70.94% at 44.25% 86.96%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(33.83% 53.57% at 35.87% 100%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(42.66% 49.72% at 45.56% 44.65%, #E1ADEB 0%, rgba(194, 166, 241, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(131.97deg, #CDF9F4 5.58%, rgba(205, 249, 232, 0) 47.58%), linear-gradient(216.44deg, rgba(192, 169, 240, 0) -16.52%, #C0A9F0 -1.04%, rgba(192, 169, 240, 0) 16.99%), linear-gradient(128.53deg, rgba(192, 169, 240, 0) 28.63%, #C0A9F0 38.5%, rgba(192, 169, 240, 0) 50.26%), radial-gradient(40.75% 97.37% at 90.75% 40.15%, #FFFDB1 0%, #FEE4BF 34.46%, #F0BDD0 69.5%, rgba(255, 129, 38, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #C2A6F1;

}

.tokenomicsSection .container .tokenomicsItem:hover svg path {
    fill: #1b0e36;
}

.tokenomicsSection .container .tokenomicsItem:hover svg g {
    opacity: 1;
}


.tokenomicsSection .container .tokenomicsItem .imageContainer {
    width: 100%;
    max-width: 40px;
}

.tokenomicsSection .container .tokenomicsItem .imageContainer .img {
    width: 100%;
}
.tokenomicsSection .container .tokenomicsItem h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin: 20px 0;
}

.tokenomicsSection .container .tokenomicsItem p {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    margin: 0 20px;
}

.tokenomicsSection > .imageContainer {
    width: 100%;
    max-width: 130px;
    /*position: relative;*/
    position: absolute;
    bottom: 0;
    right: 2%;
}

.tokenomicsSection > .imageContainer img {
    width: 100%;
}


