@media screen and (max-width: 768px) {
    .teamSection .container .usersListContainer .userListItem {
        width: calc(100% / 2);
    }

    .teamSection .container .usersListContainer .userListItem .crystal {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .teamSection .container .usersListContainer {
        flex-direction: column;
        align-items: center !important;
    }

    .teamSection .container .usersListContainer .userListItem {
        margin: 20px 0 !important;
    }
}

@media screen and (max-width: 425px) {
    .teamSection .container .usersListContainer .userListItem {
        width: 100% !important;
    }
}


