@import "responsive.css";

.teamSection {
    width: 100%;
    display: flex;
    padding: 0 0 130px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #1e0f3d;
    color: #FFFFFF;
}

.teamSection .imageContainer {
    width: 100%;
    max-width: 130px;
    position: absolute;
    top: 0;
    left: 2%;
}

.teamSection .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teamSection .container .titleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 60px;
}

.teamSection .container .usersListContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.teamSection .container .usersListContainer .userListItem {
    width: calc(100% / 3);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userListItem .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userListItem .userInfo .imageContainer {
    width: 100%;
    max-width: 240px;
    position: static;
}

.userListItem .userInfo .imageContainer img {
    width: 100%;
}

.userListItem .textContainer {
    text-align: center;
    color: #D9D4E0;
}

.userListItem .userInfo .textContainer h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin: 24px 0 12px 0;
}

.userListItem .userInfo .textContainer p {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: #D9D4E0;
    opacity: 0.7;
    letter-spacing: -0.02em;
}



