@media screen and (max-width: 768px) {
    .partners .container .swiper-slide {
        max-width: unset !important;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .partners .container .trendsContainer .swiper-button-next {
        display: none;
    }
    .partners .container .trendsContainer .swiper-button-prev {
        display: none;
    }

}