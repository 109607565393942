@media screen and (max-width: 768px){
    .blockVector .firstImageContainer {
        bottom: 70%;
    }
}

@media screen and (max-width: 425px){
    .blockVector .firstImageContainer {
        display: none;
    }

    .blockVector .secondImageContainer {
        display: none;
    }
}