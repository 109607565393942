@import "responsive.css";

.bottomNav {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 70px 0;
}

.bottomNav .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomNav .container a {
    color: #211E31;
    margin: 0 50px;
    text-decoration: none;
    cursor: pointer;
}

.bottomNav .container span {
    opacity: 0.3;
}

