.logoContainer {
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoContainer .imageContainer {
    width: 100%;
    max-width: 35px;
    height: 60px;
}

.logoContainer .imageContainer img {
    width: 100%;
}

.logoContainer h3 {
    font-family: 'Swera', sans-serif !important;
    font-size: 23px;
}