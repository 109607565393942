@import "responsive.css";

.blockVector {
    width: 100%;
    /*max-width: 100vw;*/
    background: #1e0f3d;
    position: relative;
}

.blockVector .vector {
    width: 100%;
    background: #1e0f3d;
}

.blockVector .firstImageContainer {
    width: 100%;
    max-width: 130px;
    /*background: #1e0f3d;*/
    position: absolute;
    right: 3%;
}

.blockVector .firstImageContainer img {
    width: 100%;
    height: 100%;
}

.blockVector .secondImageContainer {
    width: 100%;
    max-width: 130px;
    position: absolute;
    top: 30%;
    left: 3%;
}

.blockVector .secondImageContainer img {
    width: 100%;
    height: 100%;
}
