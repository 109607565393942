@import "./responsive.css";

.paySection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0 100px;
    background: linear-gradient(123.35deg, #EBF3D0 0%, rgba(235, 243, 208, 0) 18.4%), radial-gradient(29.9% 70.94% at 44.25% 86.96%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(33.83% 53.57% at 35.87% 100%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(42.66% 49.72% at 45.56% 44.65%, #E1ADEB 0%, rgba(194, 166, 241, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(131.97deg, #CDF9F4 5.58%, rgba(205, 249, 232, 0) 47.58%), linear-gradient(216.44deg, rgba(192, 169, 240, 0) -16.52%, #C0A9F0 -1.04%, rgba(192, 169, 240, 0) 16.99%), linear-gradient(128.53deg, rgba(192, 169, 240, 0) 28.63%, #C0A9F0 38.5%, rgba(192, 169, 240, 0) 50.26%), radial-gradient(40.75% 97.37% at 90.75% 40.15%, #FFFDB1 0%, #FEE4BF 34.46%, #F0BDD0 69.5%, rgba(255, 129, 38, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #C2A6F1;
}

.paySection .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paySection .payDescriptionContainer {
    width: calc(100% / 2);
    max-width: 565px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.paySection .payDescriptionContainer h1 {
    font-family: 'Aeonik', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #211E31;
    margin: 0 0 30px;
}

.paySection .payDescriptionContainer p {
    width: 100%;
    max-width: 450px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    margin: 0 0 50px;
}

.paySection .payDescriptionContainer .buttonContainer {
    width: 100%;
    max-width: 325px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paySection .payDescriptionContainer .buttonContainer .button.bye {
    color: #FFFFFF;
    background: #AB69CC;
}

.paySection .payDescriptionContainer .buttonContainer .button {
    color: #211E31;
    background: #ffffff;
    padding: 15px 45px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border: none;
    cursor: pointer;
}

.paySection .imageContainer {
    width: 50%;
    height: 600px;
    position: relative;
}

.paySection .imageContainer #crystal {
    width: 100%;
    position: absolute;
    left: 0;
}

.paySection .imageContainer .advantagesContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.paySection .imageContainer .advantagesContainer .advantage {
    padding: 10px 15px;
    width: 100%;
    max-width: fit-content;
    z-index: 10;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: -4px 8px 16px rgba(26, 28, 49, 0.08);
    backdrop-filter: blur(60px);
    border-radius: 6px;
    color: #ffffff;
    margin: 20px 0;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    white-space: nowrap;
    transition: all 0.3s;
    cursor: pointer;
}

.paySection .imageContainer .advantagesContainer .advantage:hover {
    color: #1b0e36;
    background: linear-gradient(123.35deg, #EBF3D0 0%, rgba(235, 243, 208, 0) 18.4%), radial-gradient(29.9% 70.94% at 44.25% 86.96%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(33.83% 53.57% at 35.87% 100%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(42.66% 49.72% at 45.56% 44.65%, #E1ADEB 0%, rgba(194, 166, 241, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(131.97deg, #CDF9F4 5.58%, rgba(205, 249, 232, 0) 47.58%), linear-gradient(216.44deg, rgba(192, 169, 240, 0) -16.52%, #C0A9F0 -1.04%, rgba(192, 169, 240, 0) 16.99%), linear-gradient(128.53deg, rgba(192, 169, 240, 0) 28.63%, #C0A9F0 38.5%, rgba(192, 169, 240, 0) 50.26%), radial-gradient(40.75% 97.37% at 90.75% 40.15%, #FFFDB1 0%, #FEE4BF 34.46%, #F0BDD0 69.5%, rgba(255, 129, 38, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #C2A6F1;
}

.paySection .imageContainer .advantagesContainer .advantage svg {
    margin-right: 15px;
}


.paySection .imageContainer .advantagesContainer .advantage.left {
    margin-right: unset;
    margin-left: auto;
}
