@media screen and ( max-width: 1024px ){
    .header .navMenu {
        display: none !important;
    }

    .header .socialLinksContainer {
        display: none !important;
    }

    .header .menuIcon {
        display: block !important;
        font-size: 25px;
    }
}
@media screen and ( max-width: 768px ){
    .header .container {
        width: 100% !important;
    }
}

