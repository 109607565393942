@import "responsive.css";

.howItWorksSection {
    width: 100%;
    height: 1512px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../images/howItWorksBg.svg") #1e0f3d;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    color: #ffffff;
}

.howItWorksSection .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 250px 0 0;
}


.howItWorksSection .container .imageContainer {
    width: 100%;
    max-width: 80px;
}

.howItWorksSection .container .imageContainer img {
    width: 100%;
}

.howItWorksSection .container h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
}

.slidesContainer {
    width: 100%;
    /*height: 100%;*/
    margin: 70px 0;
    position: static !important;
    /*display: flex;*/
    /*justify-content: center;*/
}

.howItWorksSection .swiper-slide {
    text-align: center;
    padding: 40px 30px;
    width: 100%;
    max-width: 250px;
    display: flex;
    /*height: auto !important;*/
}

.howItWorksSection .swiper-slide.swiper-slide-active.cardContainer {
    text-align: center;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    border: 3px solid transparent;
    background: linear-gradient(#1B0E36, #1B0E36) padding-box,
    linear-gradient(123.35deg, #EBF3D0 0%, rgba(235, 243, 208, 0) 18.4%), radial-gradient(29.9% 70.94% at 44.25% 86.96%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(33.83% 53.57% at 35.87% 100%, #DC8DDC 0%, rgba(220, 141, 220, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(42.66% 49.72% at 45.56% 44.65%, #E1ADEB 0%, rgba(194, 166, 241, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(131.97deg, #CDF9F4 5.58%, rgba(205, 249, 232, 0) 47.58%), linear-gradient(216.44deg, rgba(192, 169, 240, 0) -16.52%, #C0A9F0 -1.04%, rgba(192, 169, 240, 0) 16.99%), linear-gradient(128.53deg, rgba(192, 169, 240, 0) 28.63%, #C0A9F0 38.5%, rgba(192, 169, 240, 0) 50.26%), radial-gradient(40.75% 97.37% at 90.75% 40.15%, #FFFDB1 0%, #FEE4BF 34.46%, #F0BDD0 69.5%, rgba(255, 129, 38, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #C2A6F1;
}

.howItWorksSection .swiper-slide.cardContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 30px;
    border-radius: 16px;
    overflow: hidden;
    opacity: 0.3;
    border: 1px solid #D9D4E0;
}

.howItWorksSection .swiper-slide.swiper-slide-active svg #first {
    fill: #814AA1;
    opacity: 1;
}

.howItWorksSection .swiper-slide.swiper-slide-active svg g {
    opacity: 1;
}


.howItWorksSection .swiper-slide.cardContainer .cardImageContainer {
    width: 100%;
    max-width: 65px;
    margin: 0 0 30px;
}

.howItWorksSection .swiper-slide.cardContainer .cardImageContainer img {
    width: 100%;
}

.sliderButtonContainer {
    width: 100%;
    max-width: 135px;
    position: relative;
    margin: 50px 0;
}

.howItWorksSection .container .cardContainer h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin: 15px 0;
}

.howItWorksSection .container .cardContainer p {
    color: #D9D4E0;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    opacity: 0.7;
}

.howItWorksSection .container .swiper-button-prev {
    position: absolute;
    left: 0;
    width: 55px;
    height: 55px;
    transition: all 0.3s;
    cursor: pointer;
}

.howItWorksSection .container .swiper-button-prev:after {
    content: '' !important;
}

.howItWorksSection .container .swiper-button-next:after {
    content: '' !important;
}

.howItWorksSection .container .swiper-button-next {
    position: absolute;
    right: 0;
    width: 55px;
    height: 55px;
    transform: rotateY(-180deg);
    transition: all 0.3s;
    cursor: pointer;
}

.howItWorksSection .container .swiper-button-prev svg:hover circle {
    stroke: #e5b0ff;
}

.howItWorksSection .container .swiper-button-prev svg:hover path {
    fill: #e5b0ff;
}

.howItWorksSection .container .swiper-button-next svg:hover circle {
    stroke: #e5b0ff;
}

.howItWorksSection .container .swiper-button-next svg:hover path {
    fill: #e5b0ff;
}
