@import "responsive.css";

.partners {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.partners .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    position: relative;
    max-width: 1200px;
}

.partners .container h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    color: #211E31;
}

.partners .container .trendsContainer {
    width: 100%;
    height: 100%;
    margin: 70px 0 0;
    position: static !important;
}

.partners .container .swiper-slide {
    display: flex;
    justify-content: center;
}

/*.partnersButtonContainer .swiper-button-prev:after {*/
/*    content: '' !important;*/
/*}*/

.partners .container .trendsContainer .swiper-button-next {
    position: absolute !important;
    top: 58%;
    left: -5%;
    cursor: pointer;
}

.partners .container .trendsContainer .swiper-button-prev {
    position: absolute !important;
    top: 58%;
    right: -5%;
    transform: rotateY(180deg);
    cursor: pointer;
}