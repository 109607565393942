@import "./responsive.css";

.aboutUsSection {
    width: 100%;
    padding: 120px 0 0;
    background-color: #1e0f3d;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutUsSection .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aboutUsSection .container .imageContainer {
    width: 100%;
    max-width: 560px;
}

.aboutUsSection .container .imageContainer .coin {
    width: 100%;
}

.aboutUsSection .payDescriptionContainer {
    width: calc(100% / 2);
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.aboutUsSection .payDescriptionContainer h1 {
    font-family: 'Aeonik', sans-serif;
    font-weight: 700;
    font-size: 40px;
    margin: 0 0 30px;
}

.aboutUsSection .payDescriptionContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    margin: 0 0 50px;
    color: #D9D4E0;
    opacity: 0.7;
}

.aboutUsSection .payDescriptionContainer .buttonContainer {
    width: 100%;
    max-width: 325px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aboutUsSection .payDescriptionContainer .buttonContainer .button {
    color: #FFFFFF;
    background: #AB69CC;
    padding: 15px 45px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border: none;
    cursor: pointer;
}

