@import "responsive.css";

.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 100px;
}

.footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .container .socialLinksContainer {
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .container .socialLinks {
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .container .soc {
    width: 100%;
    max-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.footer .container p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #211E31;
}