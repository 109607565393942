@media screen and (max-width: 768px) {
    .howItWorksSection .container {
        width: 100% !important;
    }
}

@media screen and (max-width: 425px) {
    .howItWorksSection {
        width: 100% !important;
        padding: 100px 0 !important;
        height: 100% !important;
    }
}