@media screen and (max-width: 1440px) {
    .paySection .imageContainer img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
    }
}

@media screen and ( max-width: 768px ) {
    .paySection .container {
        flex-direction: column;
    }

    .paySection .payDescriptionContainer {
        width: 100% !important;
        max-width: unset !important;
    }

    .paySection .imageContainer .advantagesContainer .advantage {
        max-width: unset !important;
        width: unset !important;
    }

    .paySection .imageContainer img {
        width: 100%;
        height: unset;
        position: absolute;
        left: 0;
    }
    .paySection .container .imageContainer {
        width: 100%;
    }

    .paySection .advantagesContainer {
        justify-content: flex-start !important;
    }

}

@media screen and (max-width: 425px) {
    br {
        display: none;
    }
    .paySection {
        margin: 0 !important;
    }

    .paySection .payDescriptionContainer h1 {
        font-size: 40px !important;
    }
    .paySection .payDescriptionContainer p {
        margin: 0 !important;
    }

    .paySection .imageContainer {
        height: 100% !important;
    }
    .paySection .imageContainer .advantagesContainer .advantage {
        margin: 10px 0 !important;
        white-space: break-spaces !important;
    }

    .paySection .buttonContainer {
        margin: 50px 0;
    }
    .paySection .payDescriptionContainer .buttonContainer .button {
        padding: 15px 35px !important;
    }
}
