@import "./responsive.css";

.header {
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
}

.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .navMenuContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .navMenu {
    width: 100%;
    max-width: 550px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .navMenu a {
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #211E31;
    cursor: pointer;
}

.header .socialLinksContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .socialLinksContainer .socialLinks {
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .menuIcon {
    display: none;
}

.soc {
    width: 100%;
    max-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
}

.button {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    padding: 10px 30px;
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
}

