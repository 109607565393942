@media screen and (max-width: 768px){
    .aboutUsSection .container {
        flex-direction: column;
    }

    .aboutUsSection .container .payDescriptionContainer {
        max-width: unset;
        width: 100%;
    }

    .aboutUsSection .container .imageContainer img {
        width: 100%;
    }
}

@media screen and (max-width: 425px){
    .aboutUsSection {
        padding: 0;
    }
    .aboutUsSection .container .payDescriptionContainer h1 {
        font-size: 40px;
    }
}
